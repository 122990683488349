"use client";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import Link from "next/link";
import { useEffect, useState } from "react";
import { getLocalStorageItem, setLocalStorageItem } from "../dashboard/demo";
import { available_Languages, langType } from "../dashboard/state";
import AuthenticationForm from "./loginform";

const LanguagePicker = ({ lang, setLang }: { lang: langType; setLang: (v: langType) => void }) => {
   return (
      <div className=" w-fit">
         <Popover>
            <PopoverTrigger>
               <div className=" text-sm p-2 rounded hover:bg-white hover:text-ailegis-950 h-9 w-9 ">
                  {lang.toUpperCase()}
               </div>
            </PopoverTrigger>
            <PopoverContent side="right" className=" w-fit mb-2">
               <div>
                  {available_Languages.map((l, idx) => {
                     return (
                        <div
                           key={idx}
                           className={cn(
                              " cursor-pointer hover:underline rounded px-2",
                              lang === l.code ? " bg-ailegis-200" : ""
                           )}
                           onClick={() => {
                              setLang(l.code);
                           }}
                        >
                           {l.name}
                        </div>
                     );
                  })}
               </div>
            </PopoverContent>
         </Popover>
      </div>
   );
};

const dictionary = {
   welcomeMessage: {
      en: "KI-powered legal advice for modern legal protection",
      de: "KI-gestützte Rechtsberatung für den modernen Rechtsschutz",
      fr: "Conseil juridique assisté par IA pour la protection juridique moderne",
      it: "Consulenza legale basata sull'IA per la protezione legale moderna",
   },
   loginTitle: {
      en: "Login with existing account",
      de: "Login mit existierendem Account",
      fr: "Connexion avec un compte existant",
      it: "Accedi con un account esistente",
   },
   loginButton: {
      en: "Login",
      de: "Login",
      fr: "Connexion",
      it: "Accesso",
   },
   noAccountText: {
      en: "No account?",
      de: "Kein Konto?",
      fr: "Pas de compte?",
      it: "NEssun account ?",
   },
   demoLinkText: {
      en: "Try demo",
      de: "Demo ausprobieren",
      fr: "Essayer la démo",
      it: "Prova la demo",
   },
   upgradeMessageTitle: {
      en: "Upgrade for 2 Factor Authentication",
      de: "Upgrade für die Zwei-Faktor-Authentifizierung",
      fr: "Mise à niveau pour l'authentification à deux facteurs",
      it: "Aggiornamento per l'autenticazione a due fattori",
   },
   upgradeMessageContent: {
      en: <>We have reset all passwords for security purposes. Please update your password now.</>,
      de: (
         <>
            Wir haben aus Sicherheitsgründen alle Passwörter zurückgesetzt. Bitte aktualisieren Sie jetzt Ihr Passwort.
         </>
      ),
      fr: (
         <>
            Nous avons réinitialisé tous les mots de passe pour des raisons de sécurité. Veuillez mettre à jour votre
            mot de passe maintenant.
         </>
      ),
      it: <>Abbiamo reimpostato tutte le password per motivi di sicurezza. Si prega di aggiornare la password ora.</>,
   },
   upgradeInstructions: {
      en: (
         <>
            You can use the "Forgot my password" link to set a new password, or use the temporary password provided to
            you: <strong>passwort</strong>
         </>
      ),
      de: (
         <>
            Sie können den Link "Passwort vergessen" verwenden, um ein neues Passwort festzulegen, oder das Ihnen
            bereitgestellte temporäre Passwort verwenden: <strong>passwort</strong>
         </>
      ),
      fr: (
         <>
            Vous pouvez utiliser le lien "Mot de passe oublié" pour définir un nouveau mot de passe ou utiliser le mot
            de passe temporaire qui vous a été fourni : <strong>passwort</strong>
         </>
      ),
      it: (
         <>
            Puoi utilizzare il link "Ho dimenticato la password" per impostare una nuova password, oppure usare la
            password temporanea fornita: <strong>passwort</strong>
         </>
      ),
   },
   upgradeSetup: {
      en: (
         <>
            After updating your password, you'll need to set up 2-Step Verification (2FA). Follow the on-screen
            instructions. <br />
            You can easily scan the QR code with your authentication app.
         </>
      ),
      de: (
         <>
            Nach der Aktualisierung Ihres Passworts müssen Sie die Zwei-Schritt-Verifizierung (2FA) einrichten. Folgen
            Sie den Anweisungen auf dem Bildschirm. <br />
            Sie können den QR-Code einfach mit Ihrer Authentifizierungs-App scannen.
         </>
      ),
      fr: (
         <>
            Après avoir mis à jour votre mot de passe, vous devrez configurer la vérification en deux étapes (2FA).
            Suivez les instructions à l'écran. <br />
            Vous pouvez facilement scanner le code QR avec votre application d'authentification.
         </>
      ),
      it: (
         <>
            Dopo aver aggiornato la tua password, dovrai configurare la verifica in due passaggi (2FA). Segui le
            istruzioni sullo schermo. <br />
            Puoi facilmente scansionare il codice QR con la tua app di autenticazione.
         </>
      ),
   },
   upgradeSupport: {
      en: (
         <>
            If you run into any issues, feel free to contact us via email: <strong>info@ailegis.ch</strong>
         </>
      ),
      de: (
         <>
            Wenn Sie auf Probleme stossen, können Sie uns gerne per E-Mail kontaktieren:{" "}
            <strong>info@ailegis.ch</strong>
         </>
      ),
      fr: (
         <>
            Si vous rencontrez des problèmes, n'hésitez pas à nous contacter par e-mail :{" "}
            <strong>info@ailegis.ch</strong>
         </>
      ),
      it: (
         <>
            Se riscontri problemi, non esitare a contattarci via e-mail: <strong>info@ailegis.ch</strong>
         </>
      ),
   },
   continueButton: {
      en: "Continue",
      de: "Weiter",
      fr: "Continuer",
      it: "Continua",
   },
   dontShowMessage: {
      en: "Don't show message next time",
      de: "Diese Nachricht beim nächsten Mal nicht anzeigen",
      fr: "Ne plus afficher ce message",
      it: "Non mostrare più questo messaggio",
   },
};

export default function AuthenticationPage() {
   const [lang, setLang] = useState<langType>(() => {
      return getLocalStorageItem("preferredLang", "de");
   });
   const [loginform, setLoginform] = useState(<></>);

   useEffect(() => {
      setLocalStorageItem("preferredLang", lang);
      AuthenticationForm({ text: dictionary.loginButton[lang] }).then((f) => {
         setLoginform(f);
      });
   }, [lang]);

   const [showMessage, setShowMessage] = useState(false);
   const [forceShowMessage, setForceShowMessage] = useState(false);
   const [forceForceShowMessage, setForceForceShowMessage] = useState(false);
   /*
      useEffect(() => {
         const showWarning = getLocalStorageItem("showWarning", "yes") === "yes";
         setShowMessage(showWarning);
      }, []);
   */

   return (
      <>
         {forceForceShowMessage || showMessage ? (
            <>
               <div className="absolute w-screen h-screen grid place-items-center z-50">
                  <div className="bg-ailegis-50 border border-ailegis-500 max-w-[60vw] max-h-[60vh] rounded p-10 overflow-scroll no-scrollbar">
                     <div className="text-xl font-semibold">{dictionary.upgradeMessageTitle[lang]}</div>
                     <div className="mt-4">{dictionary.upgradeMessageContent[lang]}</div>
                     <div className="mt-4">{dictionary.upgradeInstructions[lang]}</div>
                     <div className="mt-4">{dictionary.upgradeSetup[lang]}</div>
                     <div className="mt-4">{dictionary.upgradeSupport[lang]}</div>
                     <div className="flex flex-col sm:flex-row justify-between gap-2">
                        <Button
                           variant="ailegis"
                           className="my-6"
                           onClick={(e) => {
                              setShowMessage(false);
                              setForceForceShowMessage(false);
                           }}
                        >
                           {dictionary.continueButton[lang]}
                        </Button>
                        <div
                           className="flex items-center space-x-2 group cursor-pointer "
                           onClick={(e) => {
                              setLocalStorageItem(
                                 "showWarning",
                                 getLocalStorageItem("showWarning", "yes") == "yes" ? "no" : "yes"
                              );
                              setForceShowMessage(!forceShowMessage);
                           }}
                        >
                           <Checkbox checked={forceShowMessage} />
                           <div className="group-hover:underline">{dictionary.dontShowMessage[lang]}</div>
                        </div>
                     </div>
                  </div>
               </div>
            </>
         ) : (
            <></>
         )}

         <div className="container relative h-[100vh] flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-2 lg:px-0">
            <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
               <div className="absolute inset-0 bg-ailegis-800 " />
               <div className="absolute inset-0 bg-ailegis-950 animate-fade-in " />
               <div className="relative z-20 mt-auto">
                  <blockquote className="space-y-2">
                     <p className="text-lg">{"AILEGIS"}</p>
                     <footer className="text-sm">{dictionary.welcomeMessage[lang]}</footer>
                  </blockquote>
               </div>
            </div>

            <div className="lg:p-8">
               <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
                  <LanguagePicker lang={lang} setLang={setLang} />
                  <div>
                     <div className="flex flex-col space-y-2 text-center">
                        <h1 className="text-2xl font-semibold tracking-tight">{dictionary.loginTitle[lang]}</h1>
                        <div className=" min-h-16">{loginform}</div>
                        <div
                           className=" bg-ailegis-50 border rounded border-ailegis-500 cursor-pointer hover:bg-ailegis-100 text-sm"
                           onClick={(e) => setForceForceShowMessage(true)}
                        >
                           {dictionary.upgradeMessageTitle[lang]}
                        </div>
                        <div className="text-sm">
                           {dictionary.noAccountText[lang]}{" "}
                           <Link href={"/demo"} className="underline">
                              {dictionary.demoLinkText[lang]}
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
